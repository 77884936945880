import React, {useState} from "react"
import Modal from "react-modal"

import * as style from "../privacy.module.css"
import Seo from "../../components/seo"
import Footer from "../../components/footer/Footer"
import ContactForm from "../../components/form/ContactForm"
import NavbarPrivacy from "../../components/navbar/NavbarPrivacy"
import {LocaleProvider} from "../../hooks/useLocale";
import useTranslation from "../../hooks/useTranslation";

const Privacy0 = ({pageContext, pageContext: {locale}}) => {
  const translations = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const customStyles = {
    content: {},
    overlay: {
      backgroundColor: "rgba(0,0,0,0.7)",
    },
  }
  Modal.setAppElement("body")
  return (
    <>
      <Seo locale={locale} translations={translations} title="privacy policy"/>
      <NavbarPrivacy locale={'pl'}/>
      <div className={style.wrapper}>
        <ol>
          {/* 1 */}
          <li>Administrator danych osobowych</li>
          <p>
            Administratorem Twoich danych osobowych jest Enterosoft Sp. z o. o.
            z siedzibą w Gdańsku, ul. Starodworska 1, 80-137 Gdańsk, KRS:
            0000850020 , NIP: 5833401725 , REGON: 386524824 (dalej: my). Możesz
            się z nami skontaktować w następujący sposób:
          </p>
          <ul>
            <li>
              listownie na adres Enterosoft Sp. z o. o., ul. Starodworska 1,
              80-137 Gdańsk
            </li>
            <li>przez e-mail: privacy@enterosoft.com</li>
          </ul>
          {/* 2 */}
          <li>Inspektor ochrony danych</li>
          <p>
            Wobec braku takiego obowiązku nie wyznaczyliśmy Inspektora ochrony
            danych
          </p>
          {/* 3 */}

          <li>Cele przetwarzania oraz podstawa prawna przetwarzania</li>
          <p>Będziemy przetwarzać Twoje dane osobowe, aby:</p>
          <ul>
            <li>
              realizować zawartą z nami umowę w szczególności realizować
              obowiązki reklamacyjne
            </li>
            <li>analizować ruch na stronie (Google Analytics)</li>
            <li>zabezpieczać stronę przed automatami (Google Recaptha)</li>
          </ul>
          <p>Realizacja zawartych umów:</p>
          <p>
            W przypadku zawierania umów na realizację usług (lub w przypadku
            zapoznania się z Regulaminem Usług) Państwa dane osobowe
            przetwarzane będą w celu wykonania i rozliczenia zawartej umowy.
            Podanie żądanych danych osobowych jest niezbędne do prawidłowej
            realizacji usług. Zgromadzone dane przetwarzane będą przez okres
            niezbędny do wykonania umowy lub do czasu wygaśnięcia roszczeń z jej
            tytułu, z uwzględnieniem obowiązujących przepisów prawa.
          </p>
          <p>
            Prowadzenie korespondencji tradycyjnej oraz elektronicznej poprzez
            e-mail lub formularz kontaktowy:
          </p>
          <ul>
            <li>
              W przypadku gdy są Państwo naszymi Klientami i wysyłają nam
              korespondencję za pośrednictwem poczty tradycyjnej lub poczty
              elektronicznej, przetwarzamy Państwa dane w celach związanych z
              realizacją zawartej umowy.
            </li>
            <li>
              Dane z korespondencji, która nie wynika z zawartych umów,
              przetwarzamy w celu udzielenia odpowiedzi na przesłane zapytania.
              W tym wypadku prowadzenie korespondencji jest naszym prawnie
              uzasadnionym interesem.
            </li>
            <li>
              Dane przesłane poprzez formularz kontaktowy umieszczony na naszej
              stronie www, przetwarzamy na podstawie udzielonej przez Państwa
              zgody. Państwa dane osobowe będą przetwarzane tylko do czasu
              nawiązania kontaktu lub do czasu wycofania zgody. Zgodę można
              wycofać w dowolnym momencie i nie będzie miało to wpływu na
              zgodność z prawem przetwarzania, którego dokonywaliśmy na
              podstawie udzielonej zgody przed jej wycofaniem. Korespondencję
              przechowujemy przez okres niezbędny do zapewnienia jej ciągłości
              jednak nie dłużej niż 6 lat.
            </li>
          </ul>
          <p>
            Kontakt telefoniczny:
          </p>
          <p>
            W przypadku kontaktu drogą telefoniczną przetwarzamy Państwa dane osobowe w celu w jakim
            zwracają się Państwo do nas oraz w zakresie jaki jest niezbędny do rozwiązania danej
            sprawy. Podanie danych nie jest w tym przypadku obowiązkowe aczkolwiek ułatwi nam
            komunikację.
          </p>
          <p>Umieszczanie informacji na publicznych kontach na portalu społecznościowym Facebook,
            LinkedIn: </p>
          <p>Prowadzenie konta na portalu społecznościowym Facebook: <a
            href='https://www.facebook.com/Enterosoft'>https://www.facebook.com/Enterosoft</a> oraz
            LinkedIn <a
              href='src/pages/pl/privacy'>https://www.linkedin.com/company/enterosoft/</a> jest
            naszym prawnie uzasadnionym interesem.
            W przypadku korzystania z tych serwisów, umieszczania na nim postów i komentarzy oraz
            korzystania z udostępnionego na serwisie Facebook komunikatora Messenger, informujemy,
            że dane osobowe będą przetwarzane w krajach mogących nie zapewnić dostatecznego poziomu
            ochrony danych osobowych, np. w USA lub innych krajach, w których serwisy mają swoje
            serwery przetwarzające dane. Dane osobowe mogą być przeglądane i gromadzone przez
            amerykańskie służby (np.: NSA, FBI, CIA) a właściciele danych nie mają możliwości
            dostępu do sądowych środków prawnych w celu obrony przeciwko przetwarzaniu danych
            osobowych przez te służby.
          </p>
          <p>
            Zbieranie danych osobowych podczas wizyty na naszej stronie
            internetowej:
          </p>
          <ul>
            <li>
              Gdy strona internetowa jest wykorzystywana jedynie w celu
              uzyskania informacji, to znaczy jeśli nie rejestrują się Państwo
              lub nie przekazują nam Państwo informacji w inny sposób, zbieramy
              tylko te dane osobowe, które są przekazywane na nasz serwer przez
              Państwa przeglądarkę. Jeśli chcą Państwo przeglądać naszą stronę
              internetową, zbieramy następujące dane, które są nam potrzebne ze
              względów technicznych, aby wyświetlać naszą stronę internetową
              oraz zagwarantować jej stabilność i bezpieczeństwo (podstawę
              prawną stanowi art. 6 ust. 1 z. 1 lit. f RODO):
            </li>
            <ul>
              <li>adres IP</li>
              <li>data i godzina zapytania</li>
              <li>
                różnica strefy czasowej w stosunku do Greenwich Mean Time (GMT)
              </li>
              <li>treść żądania (konkretna strona)</li>
              <li>status dostępu/ kod odpowiedzi HTTP</li>
              <li>przesyłana ilość danych</li>
              <li>strona internetowa, z której pochodzi żądanie</li>
              <li>przeglądarka</li>
              <li>system operacyjny i jego interfejs</li>
              <li>język i wersja oprogramowania przeglądarki.</li>
              <li>
                Podczas korzystania z naszej strony internetowej na Państwa
                komputerze zapisywane są pliki cookies. Pliki cookies to
                niewielkie pliki tekstowe, zapisywane w pamięci Państwa
                komputera i przyporządkowane do wykorzystywanej przez Państwa
                przeglądarki. Dzięki nim docierają do nas określone informacje.
                Pliki cookies nie mogą uruchamiać żadnych programów ani
                przenosić wirusów na Państwa komputer.
              </li>
              <li>
                Rodzaje plików cookies:
                <ul>
                  <li>
                    Transient cookies to pliki wykorzystywane czasowo, które są
                    automatycznie usuwane po zamknięciu przeglądarki. Zaliczają
                    się do nich w szczególności sesyjne pliki cookies. Zapisują
                    one tzw. identyfikator sesji, umożliwiający
                    przyporządkowanie różnych zapytań przeglądarki do wspólnej
                    sesji. Dzięki temu możliwe jest ponowne rozpoznanie Państwa
                    komputera, jeśli powrócą Państwo na naszą stronę
                    internetową. Sesyjne pliki cookies są usuwane po wylogowaniu
                    się lub zamknięciu przeglądarki.
                  </li>
                  <li>
                    Persistent cookies to trwałe pliki usuwane automatycznie po
                    upływie określonego czasu, który może być różny w zależności
                    od rodzaju pliku. Pliki cookies można w każdej chwili
                    usunąć, korzystając z ustawień bezpieczeństwa swojej
                    przeglądarki.
                  </li>
                </ul>
                <li>
                  Ustawienia przeglądarki można dowolnie skonfigurować i np.
                  odrzucić przyjmowanie plików cookies podmiotów trzecich lub
                  wszystkich plików cookies. Zwracamy jednak uwagę, że wówczas
                  nie będą Państwo mogli w pełni korzystać ze wszystkich funkcji
                  tej strony internetowej.
                </li>
                <li>
                  Wykorzystujemy pliki cookies do tego, by móc zidentyfikować
                  Państwa przy kolejnej wizycie.
                </li>
              </li>
            </ul>
          </ul>
          <p>Podstawą prawną przetwarzania Twoich danych osobowych jest:</p>
          <ul>
            <li>
              wykonanie i zawarcia umów (art. 6 ust. 1 lit b Rozporządzenia
              Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r.) i –
              w zakresie następujących danych: imię i nazwisko; miejsce
              zamieszkania (adres do korespondencji); PESEL; NIP; nr rachunków
              bankowych, saldo wzajemnych rozliczeń, numer telefonu, mail,
              sposób rozpatrzenia reklamacji, historia dotychczasowej
              współpracy.
            </li>
            <li>
              Nasz uzasadniony interes – w zakresie danych zebranych w toku
              współpracy i jej podejmowania musimy mieć możliwość zarówno
              prowadzenia bieżących rozliczeń i sposobu rozstrzygania
              reklamacji, dokumentowania współpracy.
            </li>
          </ul>
          {/* 4 */}
          <li>Wykorzystywanie danych w toku współpracy</li>
          <p>
            Dane wykorzystywane będą dla prowadzenia bieżących rozliczeń,
            dokumentowania realizacji umowy, rozpatrywania reklamacji, jak i
            kontaktu w wyżej wymienionych celach. A także do diagnostyki,
            analizy ruchu i skuteczności kampanii marketingowych (Goolge
            Analytics)
          </p>
          {/* 5 */}
          <li>Okres przechowywania danych osobowych</li>
          <p>
            Będziemy przechowywać Twoje dane osobowe do momentu zakończenia
            realizacji umowy (w tym w wypadku procedur reklamacyjnych do momentu
            ich zakończenia) lub definitywnego zakończenia negocjacji w sprawie
            ich zawarcia, a w wypadku zawarcia umowy również przez okres jej
            realizacji oraz okres 5 lat od końca roku kalendarzowego w którym
            zakończono współpracę.
          </p>
          {/* 6 */}
          <li>Odbiorcy danych</li>
          <p>Będziemy przekazywać Twoje dane osobowe:</p>
          <ul>
            <li>
              naszym dostawcom, którym zlecimy usługi związane z przetwarzaniem
              danych osobowych, np. dostawcom usług IT, zewnętrznym służbom
              finansowo-księgowym, podmiotom świadczącym usługi prawne. Takie
              podmioty przetwarzają dane na podstawie umowy z nami i tylko
              zgodnie z naszymi poleceniami,
            </li>
            <li>
              Google reCaptcha - strona jest chroniona przez reCAPTCHA i mają
              zastosowanie Polityka prywatności i Warunki korzystania z usług
              Google:
            </li>
            <ul>
              <li><a
                href='https://policies.google.com/privacy'>https://policies.google.com/privacy</a>
              </li>
              <li><a href="https://policies.google.com/terms">https://policies.google.com/terms</a>
              </li>
            </ul>
            <li>
              Google Analytics - ruch na stronie jest analizowany przez
              analytics i mają zastosowanie Polityka prywatności i Warunki
              korzystania z usług Google:
            </li>
            <ul>
              <li><a
                href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
              </li>
              <li><a href="https://policies.google.com/terms">https://policies.google.com/terms</a>
              </li>
            </ul>
          </ul>
          {/* 7 */}
          <li>
            Twoje prawa związane z przetwarzaniem danych osobowych i
            podejmowaniem zautomatyzowanych decyzji
          </li>
          <p>
            Przysługują Ci następujące prawa związane z przetwarzaniem danych
            osobowych:
          </p>
          <ul>
            <li>prawo wycofania zgody na przetwarzanie danych,</li>
            <li>prawo dostępu do Twoich danych osobowych,</li>
            <li>prawo żądania sprostowania Twoich danych osobowych,</li>
            <li>prawo żądania usunięcia Twoich danych osobowych,</li>
            <li>
              prawo żądania ograniczenia przetwarzania Twoich danych osobowych,
            </li>
            <li>
              prawo wyrażenia sprzeciwu wobec przetwarzania Twoich danych ze
              względu na Twoją szczególną sytuację – w przypadkach, kiedy
              przetwarzamy Twoje dane na podstawie naszego prawnie uzasadnionego
              interesu,
            </li>
            <li>
              prawo do przenoszenia Twoich danych osobowych, tj. prawo
              otrzymania od nas Twoich danych osobowych, w ustrukturyzowanym,
              powszechnie używanym formacie informatycznym nadającym się do
              odczytu maszynowego. Możesz przesłać te dane innemu
              administratorowi danych lub zażądać, abyśmy przesłali Twoje dane
              do innego administratora. Jednakże zrobimy to tylko jeśli takie
              przesłanie jest technicznie możliwe. Prawo do przenoszenia danych
              osobowych przysługuje Ci tylko co do tych danych, które
              przetwarzamy na podstawie umowy z Tobą lub na podstawie Twojej
              zgody,
            </li>
          </ul>
          <p>Aby skorzystać z powyższych praw, skontaktuj się z nami.</p>
          {/* 8 */}
          <li>Zakres wymogu podania danych osobowych</li>
          <ul>
            <li>
              Wskazujemy iż w zakresie procesu zawierania umowy bez podania
              przez Ciebie swoich danych osobowych w zakresie wskazanym nie
              jesteśmy w stanie skutecznie zawrzeć a później realizować Umowę,
              tym samym odmowa ich podania skutkować będzie brakiem
              rozpatrywania możliwości współpracy. Wskazujemy, iż w zależności
              do zakresu i sposobu realizacji umowy możliwe jest wykluczenie
              podawania nr NIP, PESEL, mail, telefon, konto bankowe.
            </li>
            <li>
              W współpracy posiadanie wskazanych danych osobowych jest wymogiem
              prawnym wynikającym z kodeksu cywilnego, kodeksu postępowania
              cywilnego i ustaw podatkowych, tym samym brak ich podania
              uniemożliwia nam realizację wiążących nas umów i skutkować będzie
              odmową ich zawarcia, a w niektórych wypadkach odmową przystąpienia
              do ich realizacji lub uniemożliwieniem realizacji Twoich
              uprawnień, w szczególności uprawnień dot. reklamacji.
            </li>
          </ul>
          {/* 9 */}
          <li>Zautomatyzowane przetwarzanie danych i profilowanie</li>
          <ul>
            <li>
              Wskazujemy iż w zakresie danych dotyczących sposobu realizowania
              przez usług na naszą rzecz, możliwe jest wprowadzenie rozwiązań
              informatycznych skutkujących automatyczne notyfikowanie
              poszczególnych kategorii zdarzeń Administratorowi, w szczególności
              salda i częstotliwości rozliczeń czy preferencji zakupowych.
              Każdorazowo taka informacja wyłącznie w wypadku wyrażenia
              stosownych zgód lub dobrowolnego korzystania z rozwiązań
              udostępnianych przez nas skutkować może jedynie prezentowaniem
              Tobie ewentualnych propozycji zakupu towarów lub usług.
            </li>
            <li>
              Nasza strona internetowa korzysta z Google Analytics, usługi
              analizowania usług internetowych, oferowanej przez Google Inc.
              („Google”). Google Analytics wykorzystuje tzw. „Cookies”, pliki
              tekstowe, które są zapisywane na Państwa komputerze i umożliwiają
              analizę korzystania przez Państwa ze strony internetowej. Uzyskane
              przez plik cookie informacje dotyczące sposobu korzystania przez
              Państwa z tej strony internetowej.
            </li>
            <li>
              Korzystamy z Google Analytics w celu analizowania korzystania z
              naszej strony internetowej oraz jej regularnego usprawniania.
              Dzięki uzyskanym statystykom możemy poprawiać naszą ofertę i
              uczynić ją bardziej interesującą dla Państwa jako użytkowników. W
              odniesieniu do wyjątkowych przypadków, w których dane osobowe
              przekazywane są do USA, Google podporządkowuje się porozumieniu
              EU-US Privacy Shield, <a
              href='www.privacyshield.gov/EU-US-Framework'>www.privacyshield.gov/EU-US-Framework</a>.
              Podstawę prawną dla korzystania z Google Analytics stanowi art. 6
              ust. 1 zd. 1 lit. f RODO.
            </li>
            <li>
              Informacje dostawcy zewnętrznego: Google Dublin, Google Ireland
              Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, fax: +353
              (1) 436 1001. Warunki korzystania:
              <a href='https://policies.google.com/privacy'>https://policies.google.com/privacy</a>,
              informacje dotyczące ochrony
              danych osobowych: <a
              href='https://policies.google.com/terms'>https://policies.google.com/terms</a>.
            </li>
          </ul>
          <p>Prawo wycofania zgody</p>
          <p>
            W zakresie, w jakim Twoje dane są przetwarzane na podstawie zgody
            masz prawo wycofania zgody na przetwarzanie danych w dowolnym
            momencie. Wycofanie zgody nie ma wpływu na zgodność z prawem
            przetwarzania, którego dokonano na podstawie Twojej zgody przed jej
            wycofaniem. Zgodę możesz wycofać poprzez wysłanie oświadczenia o
            wycofaniu zgody na nasz adres korespondencyjny, lub nasz adres
            emailowy.
          </p>
          <p>Prawo wniesienia skargi do organu</p>
          <p>
            Przysługuje Ci także prawo wniesienia skargi do organu nadzorczego
            zajmującego się ochroną danych osobowych, tj. Prezesa Urzędu Ochrony
            Danych Osobowych.
          </p>
        </ol>
      </div>
      <Footer locale={locale} translations={translations} openModal={() => setModalOpen(true)}/>
      <Modal className="contact__modal" style={customStyles} isOpen={modalOpen}>
        <ContactForm translations={translations} locale={locale}
                     closeModal={() => setModalOpen(false)}/>
      </Modal>
    </>

  )
}



const PrivacyPL = ({pageContext, children}) => {
  return (
    <LocaleProvider>
      <Privacy0 pageContext={pageContext}>{children}</Privacy0>
    </LocaleProvider>
  )
}
export default PrivacyPL